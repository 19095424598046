export default {
  namespaced: true,
  state: {
    roles: [
      { name: "Yönetici", color: "primary" },
      { name: "Müşteri", color: "secondary" },
      { name: "Kullanıcı", color: "gray" },
    ],
  },
  getters: {
    _roles(state) {
      return state.roles;
    },
  },
  mutations: {
    setRoles(state, payload) {
      state.roles = payload;
    },
  },
  actions: {},
};
