<template>
  <div class="header-banner">
    <div class="content">
      <h5 class="title">{{ title }}</h5>
      <p class="description">
        {{ description }}: <strong class="text-primary">{{ version }}</strong>
      </p>
    </div>
    <img
      src="@/assets/header-banner.png"
      alt="header-banner-img"
      class="image"
      width="128"
    />
  </div>
</template>

<script>
export default {
  name: "HeaderBanner",
  props: ["title", "description", "version"],
};
</script>

<style lang="scss" scoped>
.header-banner {
  border-radius: 0.5rem;
  background: rgba(var(--rgb-color-primary), 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  overflow: hidden;
  padding: 0 1.5rem;
  .title {
    margin-bottom: 0.5rem;
    color: var(--color-dark);
  }
  .description {
    font-size: 0.9rem;
    margin-bottom: 0;
  }
  .image {
    margin-right: -0.5rem;
    margin-top: auto;
    margin-bottom: -2rem;
  }
}
</style>
