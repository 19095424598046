<template>
  <header class="header">
    <div class="container">
      <div class="profile-text me-auto">
        <img
          :src="`https://www.gravatar.com/avatar/${md5(_admin.email)}?s=64`"
          alt="profile-photo"
          class="profile-photo"
          width="32"
        />
        <div class="vertical">
          <span class="name">{{ _admin.full_name }}</span>
          <span class="role" :class="`bg-${_roles?.[_admin.role]}`">{{
            _roles?.[_admin.role]?.name
          }}</span>
        </div>
      </div>
      <div class="header-icons">
        <button class="btn btn-sm btn-danger" @click="logout">
          <span class="small">Güvenli çıkış</span>
          <i class="bi bi-box-arrow-right ms-2"></i>
        </button>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import md5 from "crypto-js/md5";

export default {
  name: "AppHeader",
  computed: {
    ...mapGetters({ _admin: "Auth/_admin", _roles: "Global/_roles" }),
  },
  methods: {
    logout() {
      this.$store.dispatch("Auth/LOGOUT");
      this.$router.push({ name: "Login" });
      this.$swal({
        icon: "success",
        text: "Güvenli çıkış sağlandı, görüşmek üzere.",
      });
    },
    md5,
  },
};
</script>

<style lang="scss" scoped>
header.header {
  .container {
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .profile-text {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .profile-photo {
        border-radius: 50%;
        margin-right: 0.5rem;
      }
      .vertical {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        line-height: 105%;
        .name {
          color: var(--color-dark);
          font-weight: 700;
          font-size: 0.95rem;
        }
        .role {
          color: var(--color-primary);
          font-size: 0.75rem;
          font-weight: 500;
        }
      }
    }
  }
}
</style>
