import axios from "axios";

export default {
  namespaced: true,
  state: {
    token: null,
    auth: false,
    admin: {},
  },
  getters: {
    _token(state) {
      return state.token;
    },
    _auth(state) {
      return state.auth;
    },
    _admin(state) {
      return state.admin;
    },
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload;
    },
    setAuth(state, payload) {
      state.auth = payload;
    },
    setAdmin(state, payload) {
      state.admin = payload;
    },
  },
  actions: {
    SET_TOKEN(context, payload) {
      payload
        ? localStorage.setItem("JWT_AUTH_TOKEN", payload)
        : localStorage.removeItem("JWT_AUTH_TOKEN");
      context.commit("setToken", payload);
    },
    LOGIN(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/login", payload)
          .then((res) => {
            context.commit("setAuth", true);
            context.commit("setAdmin", res.data.admin);
            context.dispatch("SET_TOKEN", res.data.session.token);
            resolve(res.data);
          })
          .catch((err) => reject(err?.response?.data || err));
      });
    },
    FAST_LOGIN_SMS(_, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/admin/fast-login-sms`, payload)
          .then((res) => resolve(res.data))
          .catch((err) => reject(err?.response?.data || err));
      });
    },
    FAST_LOGIN_VERIFY(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/fast-login-verify", payload)
          .then((res) => {
            context.commit("setAuth", true);
            context.commit("setAdmin", res.data.admin);
            context.dispatch("SET_TOKEN", res.data.session.token);
            resolve(res.data);
          })
          .catch((err) => reject(err?.response?.data || err));
      });
    },
    CHECK_AUTH(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/auth/${context.getters._token}`)
          .then((res) => {
            context.commit("setToken", res.data.token);
            context.commit("setAuth", true);
            context.commit("setAdmin", res.data.admin);
            resolve(res.data);
          })
          .catch((err) => {
            localStorage.removeItem("JWT_AUTH_TOKEN");
            context.commit("setToken", null);
            context.commit("setAuth", false);
            context.commit("setAdmin", {});
            reject(err?.response?.data || err);
          });
      });
    },
    LOGOUT(context) {
      localStorage.removeItem("JWT_AUTH_TOKEN");
      context.commit("setToken", null);
      context.commit("setAuth", false);
      context.commit("setAdmin", {});
    },
  },
};
