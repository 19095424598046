<template>
  <aside class="sidebar">
    <div class="container-fluid d-flex flex-column h-100">
      <router-link :to="{ name: 'Home' }" class="logo mt-3">
        <strong>Dalya Webpanel</strong>
      </router-link>
      <ul class="menu">
        <li>
          <router-link :to="{ name: 'Home' }">
            <i class="bi bi-box"></i>
            Giriş & Kullanım
          </router-link>
        </li>
        <li>
          <transition mode="out-in">
            <template v-if="!websites_loading">
              <select class="form-select" v-model="active_site">
                <option :value="null" selected disabled>- Web sayfası -</option>
                <option
                  v-for="site in _website_list"
                  :key="site"
                  :value="site._id">
                  {{ site.name }}
                </option>
              </select>
            </template>
            <div class="text-center loader mt-4" v-else>
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </transition>
        </li>
        <transition>
          <li v-if="_active_site !== null">
            <router-link
              :to="{
                name: 'Slides',
                params: { websiteId: _active_site ?? '--' },
              }"
              class="sub-menu">
              <i class="bi bi-images"></i>
              Slaytlar
            </router-link>
          </li>
        </transition>
        <transition>
          <li v-if="_active_site !== null">
            <router-link
              :to="{
                name: 'Texts',
                params: { websiteId: _active_site ?? '--' },
              }"
              class="sub-menu">
              <i class="bi bi-list-nested"></i>
              Kayan Yazı
            </router-link>
          </li>
        </transition>
      </ul>

      <button class="btn btn-secondary d-block mt-auto mb-3" @click="AddSite">
        <i class="bi bi-plus-lg"></i>
        Site Ekle
      </button>
    </div>
  </aside>
</template>

<script>
import { mapGetters } from "vuex";
import md5 from "crypto-js/md5";

export default {
  name: "AppHeader",
  data: () => ({
    active_site: null,
    websites_loading: false,
  }),
  computed: {
    ...mapGetters({
      _auth: "Auth/_auth",
      _admin: "Auth/_admin",
      _roles: "Global/_roles",
      _website_list: "Website/_website_list",
      _active_site: "Website/_active_site",
    }),
  },
  watch: {
    active_site(val) {
      this.$store.commit("Website/setActiveSite", val);
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("Auth/LOGOUT");
      this.$router.push({ name: "Login" });
      this.$swal({
        icon: "success",
        text: "Güvenli çıkış sağlandı, görüşmek üzere.",
      });
    },
    AddSite() {
      this.$swal({
        title: "Site adını giriniz.",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        timer: 0,
        position: "",
        showConfirmButton: true,
        showLoaderOnConfirm: true,
        preConfirm: async (site_name) => {
          try {
            await this.$store.dispatch("Website/WEBSITE_CREATE", site_name);
          } catch (error) {
            this.$swal.showValidationMessage(`Site Oluşturulamadı`);
          }
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            title: `Site başarıyla oluşturuldu`,
            icon: "success",
          });
        }
      });
    },
    md5,
  },
  updated() {
    if (!this._auth) return;
    this.websites_loading = true;
    setTimeout(() => {
      this.$store
        .dispatch("Website/WEBSITE_LIST")
        .then(() => {
          if (this.$route.params?.websiteId)
            this.active_site = this.$route.params.websiteId;
        })
        .catch((err) =>
          this.$swal({
            icon: "error",
            text: err?.message || err,
          })
        )
        .finally(() => (this.websites_loading = false));
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
aside.sidebar {
  width: 250px;
  height: 100%;
  border-right: 1px solid #e8e8e8;
  .container-fluid {
    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0.75rem 0 1rem;
      text-decoration: none;
      color: var(--color-dark);
      strong {
        text-transform: uppercase;
        margin-left: 0.5rem;
        font-size: 1.05rem;
      }
    }
    .menu {
      padding-left: 0;
      list-style: none;
      li .form-select {
        width: calc(100% - 1rem);
        padding: 0.75rem 1rem;
        padding-right: 2rem;
        margin: 0.5rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 1rem;
        option {
          padding: 0.75rem 1rem;
        }
      }
      li a {
        display: flex;
        align-items: center;
        font-size: 0.95rem;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;
        text-decoration: none;
        color: var(--color-dark);
        margin: 0.5rem;
        transition: background 150ms ease-in-out;
        &.sub-menu {
          padding: 0.5rem 1rem;
          margin-left: 1.5rem;
          font-size: 0.9rem;
        }
        &:hover {
          color: var(--color-primary);
          background: #f7f7f7;
        }
        &.router-link-exact-active {
          background: var(--color-primary);
          color: var(--color-light);
        }
        .bi {
          margin-right: 0.75rem;
        }
      }
    }
  }
}
</style>
