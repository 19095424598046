<template>
  <div class="home-view">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <HeaderBanner
            title="Hoşgeldiniz!"
            description="Arkadyas Webpanel'in son sürümünü kullanıyorsunuz, version"
            version="1.0.0"
          />
        </div>
      </div>
    </div>
    <div class="text-center text-muted">
      <h3 class="mt-5">Dalya Tur Webpanel</h3>
      <p class="w-50 mx-auto">
        Merhaba sn.
        <strong class="text-primary"> {{ _admin.full_name }} </strong>,
        aşağıdaki adımları takip ederek web sayfalarınızı yönetebilirsiniz.
      </p>
    </div>
    <div class="container">
      <ol class="list-group">
        <li v-for="(step, i) in steps" :key="step" class="list-group-item">
          <strong>{{ ++i }}-</strong> {{ step.content }}
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HeaderBanner from "@/components/shared/HeaderBanner";

export default {
  name: "HomeView",
  components: {
    HeaderBanner,
  },

  data: () => ({
    steps: [
      {
        content: "Sol menüden yönetmek istediğiniz web sayfasını seçiniz.",
      },
      {
        content:
          "Hemen altında düzenleme yapabileceğiniz bölümler listelenecektir, düzenlemek istediğiniz bölümü seçip devam edebilirsiniz.",
      },
      {
        content:
          "Hepsi bu kadar! Yaptığınız değişikliği kontrol ettikten sonra güvenli çıkış yapabilirsiniz.",
      },
    ],
  }),
  computed: {
    ...mapGetters({ _admin: "Auth/_admin" }),
  },
};
</script>
