import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

/* Axios */
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_BASE || "/api";

/* Sweetalert 2 */
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

/* Bootstrap 5 */
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";

/* Bootstrap Icons */
import "bootstrap-icons/font/bootstrap-icons.css";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueSweetalert2, {
  toast: true,
  showConfirmButton: false,
  confirmButtonText: "Tamam",
  cancelButtonText: "İptal",
  timerProgressBar: true,
  timer: 6 * 1000,
  position: "bottom-end",
});
app.mount("#app");
