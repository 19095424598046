<template>
  <div class="app-wrapper">
    <transition name="sidebar" mode="out-in">
      <Sidebar v-show="_auth" />
    </transition>
    <div class="main-content">
      <transition name="header" mode="out-in">
        <Header v-show="_auth" />
      </transition>
      <router-view v-slot="{ Component }">
        <transition name="router" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/shared/Header";
import Sidebar from "@/components/shared/Sidebar";

export default {
  name: "App",
  components: {
    Header,
    Sidebar,
  },
  computed: {
    ...mapGetters({ _auth: "Auth/_auth", _token: "Auth/_token" }),
  },
  beforeCreate() {
    this.$store.dispatch(
      "Auth/SET_TOKEN",
      localStorage.getItem("JWT_AUTH_TOKEN") || ""
    );
    this.$router.beforeEach((to, from, next) => {
      if (to.meta.auth && !this._token) {
        return next({ name: "Login" });
      } else if (to.meta.auth && this._token) {
        return this.$store
          .dispatch("Auth/CHECK_AUTH")
          .then(() => next())
          .catch((err) => {
            next({ name: "Login" });
            return this.$swal({
              icon: "error",
              text: err.message,
            });
          })
          .finally(() => {
            // alert("OK");
          });
      }
      if (to.name === "Login" && this._token) return next("/");
      next();
    });
  },
  mounted() {
    [...document.querySelectorAll('[data-bs-toggle="tooltip"]')].map(
      (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl)
    );
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");

:root {
  --color-primary: #5d87ff;
  --color-secondary: #49beff;
  --color-dark: #323232;
  --color-light: #f8f8f8;
  --color-success: #42e5c6;
  --color-warning: #feb83d;
  --color-danger: #fb896b;

  --rgb-color-primary: 93, 135, 255;
  --rgb-color-secondary: 73, 190, 255;
  --rgb-color-success: 66, 229, 198;
  --rgb-color-warning: 254, 184, 61;
  --rgb-color-danger: 251, 137, 107;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  &:not(input) {
    user-select: none;
  }
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Mulish, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.app-wrapper {
  height: 100vh;
  display: flex;
  align-items: stretch;
  .main-content {
    flex: 1;
  }
}

fieldset.field {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  .form-label {
    font-size: 0.9rem;
    font-weight: 500;
    margin-bottom: 0.35rem;
  }
  .form-control {
    font-size: 0.9rem;
    padding: 0.5rem 0.75rem;
  }
}

.btn-circle {
  border-radius: 50% !important;
  width: 40px;
  height: 40px;
  padding: 0 !important;
  transition: all 300ms ease-in-out;
  &:active {
    border-color: #a8a8a8 !important;
  }
  &:hover {
    background: #e8e8e8 !important;
  }
}

.btn-primary {
  --bs-btn-bg: #5d87ff !important;
  --bs-btn-border-color: #5d87ff !important;
  --bs-btn-disabled-bg: #5d87ff !important;
  --bs-btn-disabled-border-color: #5d87ff !important;
}

.btn-secondary {
  --bs-btn-bg: #49beff !important;
  --bs-btn-border-color: #49beff !important;
  --bs-btn-hover-bg: #45a2d4 !important;
  --bs-btn-hover-border-color: #45a2d4 !important;
  --bs-btn-active-bg: #45a2d4 !important;
  --bs-btn-active-border-color: #3e98c8 !important;
  --bs-btn-disabled-bg: #49beff !important;
  --bs-btn-disabled-border-color: #49beff !important;
}

.sidebar-enter-from {
  opacity: 0;
  margin-left: -250px;
}
.sidebar-enter-to,
.sidebar-leave-from {
  opacity: 1;
  margin-left: 0;
}
.sidebar-leave-to {
  opacity: 0;
  margin-left: -250px;
}
.sidebar-enter-active,
.sidebar-leave-active {
  transition: all 150ms ease-in-out;
}

.header-enter-from {
  opacity: 0;
  margin-top: -75px;
}
.header-enter-to,
.header-leave-from {
  opacity: 1;
  margin-top: 0;
}
.header-leave-to {
  opacity: 0;
  margin-top: -75px;
}
.header-enter-active,
.header-leave-active {
  transition: all 150ms ease-in-out;
}

.router-enter-from {
  opacity: 0;
}
.router-enter-to,
.router-leave-from {
  opacity: 1;
}
.router-leave-to {
  opacity: 0;
}
.router-enter-active,
.router-leave-active {
  transition: all 150ms ease-in-out;
}

.v-enter-from {
  opacity: 0;
}
.v-enter-to,
.v-leave-from {
  opacity: 1;
}
.v-leave-to {
  opacity: 0;
}
.v-enter-active,
.v-leave-active {
  transition: all 300ms ease-in-out;
}
</style>
