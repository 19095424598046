import { createStore } from "vuex";
import Auth from "./Auth";
import Global from "./Global";
import Website from "./Website";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    Auth,
    Global,
    Website,
  },
});
