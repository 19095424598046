import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "@/views/HomeView";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
    meta: {
      auth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/LoginView"),
  },
  {
    path: "/slides/:websiteId",
    name: "Slides",
    component: () => import("@/views/SlidesView"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/texts/:websiteId",
    name: "Texts",
    component: () => import("@/views/TextView"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/:pathMatch(.*)",
    name: "NotFound",
    component: () => import("@/views/NotFound"),
    meta: {
      auth: true,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
