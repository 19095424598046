import axios from "axios";

export default {
  namespaced: true,
  state: {
    active_site: null,
    website_list: [],
    slide_list: [],
    texts: "",
    slide_list_loading: false,
    texts_loading: false,
  },
  getters: {
    _active_site(state) {
      return state.active_site;
    },
    _website_list(state) {
      return state.website_list;
    },
    _slide_list(state) {
      return state.slide_list;
    },
    _texts(state) {
      return state.texts;
    },
    _slide_list_loading(state) {
      return state.slide_list_loading;
    },
    _texts_loading(state) {
      return state.texts_loading;
    },
  },
  mutations: {
    setActiveSite(state, payload) {
      state.active_site = payload;
    },
    setWiteList(state, payload) {
      state.website_list = payload;
    },
    setSlideList(state, payload) {
      state.slide_list = payload;
    },
    setTexts(state, payload) {
      state.texts = payload;
    },
    setSlideListLoading(state, payload) {
      state.slide_list_loading = payload;
    },
    setTextsLoading(state, payload) {
      state.texts_loading = payload;
    },
  },
  actions: {
    WEBSITE_LIST(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/website/list`, {
            headers: {
              Authorization: `Bearer ${context.rootGetters["Auth/_token"]}`,
            },
          })
          .then((res) => {
            context.commit("setWiteList", res.data.website_list);
            resolve(res.data);
          })
          .catch((err) => reject(err?.response?.data || err));
      });
    },
    WEBSITE_CREATE(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `/website/create`,
            { name: payload },
            {
              headers: {
                Authorization: `Bearer ${context.rootGetters["Auth/_token"]}`,
              },
            }
          )
          .then((res) => {
            context
              .dispatch("WEBSITE_LIST")
              .then(() => resolve(res.data))
              .catch((err) => {
                this.$swal({
                  icon: "error",
                  text: err?.message || err,
                });
                reject(err?.response?.data || err);
              });
          })
          .catch((err) => reject(err?.response?.data || err));
      });
    },
    CREATE_SLIDE(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `/website/create-slide/${context.getters._active_site}`,
            payload,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${context.rootGetters["Auth/_token"]}`,
              },
            }
          )
          .then((res) => {
            context
              .dispatch("SLIDE_LIST", context.getters._active_site)
              .then(() => resolve(res.data))
              .catch((err) => {
                this.$swal({
                  icon: "error",
                  text: err?.message || err,
                });
                reject(err?.response?.data || err);
              });
          })
          .catch((err) => reject(err?.response?.data || err));
      });
    },
    SLIDE_LIST(context, payload) {
      context.commit("setSlideListLoading", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`/website/list-slides/${payload}`, {
            headers: {
              Authorization: `Bearer ${context.rootGetters["Auth/_token"]}`,
            },
          })
          .then((res) => {
            context.commit("setSlideList", res.data.slide_list);
            resolve(res.data);
          })
          .catch((err) => reject(err?.response?.data || err))
          .finally(() => context.commit("setSlideListLoading", false));
      });
    },
    TEXT_LIST(context, payload) {
      context.commit("setTextsLoading", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`/website/texts/${payload}`, {
            headers: {
              Authorization: `Bearer ${context.rootGetters["Auth/_token"]}`,
            },
          })
          .then((res) => {
            context.commit("setTexts", res.data.website.texts);
            resolve(res.data);
          })
          .catch((err) => reject(err?.response?.data || err))
          .finally(() => context.commit("setTextsLoading", false));
      });
    },
    SORT_SLIDES(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `/website/sort-slides/${context.getters._active_site}`,
            {
              slides: payload,
            },
            {
              headers: {
                Authorization: `Bearer ${context.rootGetters["Auth/_token"]}`,
              },
            }
          )
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => reject(err?.response?.data || err));
      });
    },
    UPDATE_SLIDE(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `/website/update-slide/${payload._id}`,
            {
              link: payload.link,
            },
            {
              headers: {
                Authorization: `Bearer ${context.rootGetters["Auth/_token"]}`,
              },
            }
          )
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => reject(err?.response?.data || err));
      });
    },
    SAVE_TEXTS(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `/website/update-texts/${context.getters._active_site}`,
            {
              text: payload,
            },
            {
              headers: {
                Authorization: `Bearer ${context.rootGetters["Auth/_token"]}`,
              },
            }
          )
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => reject(err?.response?.data || err));
      });
    },
    DELETE_SLIDE(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `/website/delete-slide/${context.getters._active_site}/${payload.slide_id}`,
            {
              headers: {
                Authorization: `Bearer ${context.rootGetters["Auth/_token"]}`,
              },
            }
          )
          .then((res) => {
            context
              .dispatch("SLIDE_LIST", context.getters._active_site)
              .then(() => resolve(res.data))
              .catch((err) => {
                this.$swal({
                  icon: "error",
                  text: err?.message || err,
                });
                reject(err?.response?.data || err);
              });
          })
          .catch((err) => reject(err?.response?.data || err));
      });
    },
  },
};
